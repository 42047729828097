<template>
<div class='settingsNav'>
	<router-link tag='div' :to='{ name: "managerSettingsPersonal" }'><div>{{$t('elements.buttons.personalInfo')}}</div></router-link>
	<router-link tag='div' :to='{ name: "managerSettingsClinic" }'><div>Company Info</div></router-link>
	<router-link tag='div' :to='{ name: "managerSettingsPassword" }'><div>{{$t('elements.buttons.password')}}</div></router-link>
	<router-link tag='div' :to='{ name: "managerSettingsSecurity" }'><div>{{$t('elements.buttons.security')}}</div></router-link>
	<router-link v-if='$store.state.profile.organization.billing' tag='div' :to='{ name: "managerSettingsBilling" }'><div>{{$t('elements.buttons.billing')}}</div></router-link>
</div>
</template>

<script>
export default {
	name: 'SettingsNav'
}
</script>

<style lang='scss'>
@import '@/components/clinician/settings/scss/nav.scss';
</style>
