<template>
<div class='settings'>
	<Nav />
	<router-view />
</div>
</template>

<script>
import { simpleState } from '@/store/helpers'
import Nav from './Nav'

export default {
	name: 'ManagerSettings',
	components: { Nav },
	watch: {
		$route: (current) => simpleState.settingsPath = current.path
	}
}
</script>

<style lang='scss'>
@import '@/components/clinician/settings/scss/settings.scss';
</style>
