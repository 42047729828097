<template>
<div class='managerSettingsBilling'><div>
	<header>{{$t('views.settings.billing.title')}}</header>
	<div class='controlBar'>
		<a class='iconTextButton neutral' :href='billingUrl' target='_blank'>
			<svg class='smallIcon'>
				<use xlink:href='#billing' />
			</svg>
			<span class='label'>{{$t('views.settings.billing.btnBilling')}}</span>
		</a>
	</div>
</div></div>
</template>

<script>
import { apiPath } from '@/configuration/settings'
export default {
	name: 'ManagerSettingsBilling',
	data: () => ({ billingUrl: apiPath + '/billing' })
}
</script>

<style lang='scss'>
.managerSettingsBilling {
	padding-left: $size-gutter * 4;
	background: $color-white;

	> div {
		position: relative;
		max-width: 500px;
	}
}
</style>
